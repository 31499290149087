const canvasGridAngle = document.getElementById('gridAngleCanvas');
const ctxGridAngle = canvasGridAngle.getContext('2d');

let pointGridAngleRadius = 1.5; // Radius of each point
let numGridAngleColumns = 30; // Number of columns
let numGridAngleLines = 15; // Number of lines
let tiltGridAngle = 70 * Math.PI / 180; // Convert 70 degrees to radians
const perspectiveGridAngle = 800; // Perspective distance
let offsetGridAngle = 0;
const gridWidthFactor = 1.2; // Width of the grid (120% of screen width)
const heightCorrectionFactor = 0.8; // Factor to correct height for perspective effect

function resizeGridAngleCanvas() {
	canvasGridAngle.width = 1400; // Set canvas width to 100% of screen width

	if ( window.matchMedia( '(min-width: 1200px)' ).matches ) {
		canvasGridAngle.width = 1400;
		pointGridAngleRadius = 1.5;
		numGridAngleColumns = 30;
		numGridAngleLines = 15;
		tiltGridAngle = 70 * Math.PI / 180;
	} else if ( window.matchMedia( '(min-width: 768px) and (max-width: 1199px)' ).matches ) {
		canvasGridAngle.width = window.innerWidth;
		pointGridAngleRadius = 1.5;
		numGridAngleColumns = 20;
		numGridAngleLines = 10;
		tiltGridAngle = 60 * Math.PI / 180;
	} else if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
		canvasGridAngle.width = window.innerWidth;
		pointGridAngleRadius = 1;
		numGridAngleColumns = 12;
		numGridAngleLines = 10;
		tiltGridAngle = 60 * Math.PI / 180;
	}

	canvasGridAngle.height = calculateGridAngleHeight() * heightCorrectionFactor; // Adjust height for perspective
	drawGridAngle();
}

function calculateGridAngleHeight() {
	const gridWidth = canvasGridAngle.width * gridWidthFactor; // Width of the grid
	const distance = gridWidth / numGridAngleColumns; // Calculate distance based on number of columns
	return distance * numGridAngleLines; // Height based on number of lines
}

function drawGridAngle() {
	ctxGridAngle.clearRect(0, 0, canvasGridAngle.width, canvasGridAngle.height);

	const gridWidth = canvasGridAngle.width * gridWidthFactor; // Width of the grid
	const columns = numGridAngleColumns;
	const rows = numGridAngleLines; // Number of lines as rows
	const distance = gridWidth / columns; // Calculate distance based on number of columns

	const offsetX = (gridWidth - canvasGridAngle.width) / 2; // Center the grid horizontally

	for (let row = 0; row <= rows; row++) {
		for (let col = 0; col <= columns; col++) {
			const x = col * distance - offsetX; // Adjust for horizontal centering
			let y = (row * distance + offsetGridAngle) % (calculateGridAngleHeight() + distance); // Adjust for smoother transition

			if (y > calculateGridAngleHeight()) y -= calculateGridAngleHeight() + distance; // Wrap around to top if necessary

			const z = 0; // Initial z-coordinate (flat grid)
			const opacity = (y + distance) / calculateGridAngleHeight(); // Calculate opacity based on y position
			const transformedPoint = transformGridAnglePoint(x, y, z, tiltGridAngle);
			if (transformedPoint) {
				drawGridAnglePoint(transformedPoint.x, transformedPoint.y, pointGridAngleRadius, opacity);
			}
		}
	}
}

function transformGridAnglePoint(x, y, z, angle) {
	const centerX = canvasGridAngle.width / 2;
	const centerY = canvasGridAngle.height / 2;
	const dx = x - centerX;
	const dy = y - centerY;

	const rotatedY = dy * Math.cos(angle) - z * Math.sin(angle);
	const rotatedZ = dy * Math.sin(angle) + z * Math.cos(angle);

	const perspectiveScale = perspectiveGridAngle / (perspectiveGridAngle - rotatedZ);
	const finalX = dx * perspectiveScale + centerX;
	const finalY = rotatedY * perspectiveScale + centerY;

	if (finalX >= 0 && finalX <= canvasGridAngle.width && finalY >= 0 && finalY <= canvasGridAngle.height) {
		return { x: finalX, y: finalY };
	}
	return null;
}

function drawGridAnglePoint(x, y, size, opacity) {
	ctxGridAngle.beginPath();
	ctxGridAngle.arc(x, y, size, 0, Math.PI * 2);
	ctxGridAngle.fillStyle = `rgba(255, 255, 255, ${opacity})`; // Set fill style with calculated opacity
	ctxGridAngle.fill();
}

function animateGridAngle() {
	offsetGridAngle += 0.25; // Move points from top to bottom at quarter speed
	if (offsetGridAngle >= calculateGridAngleHeight()) {
		offsetGridAngle = 0; // Reset offset to avoid large numbers
	}
	drawGridAngle();
	requestAnimationFrame(animateGridAngle);
}

window.addEventListener('resize', resizeGridAngleCanvas);
resizeGridAngleCanvas();
animateGridAngle();
